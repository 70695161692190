@import 'styles/variables';
@import 'styles/mixins';

$buttonActive: #0046b4;
$buttonHover: #064dbe;

.button {
  width: 162px;
  height: 52px;
  border: none;
  background-color: $colorBackgroundMainBlue;
  box-shadow: 4px 4px 35px rgba(21, 17, 220, 0.05);
  border-radius: 16px;
  color: #f4f8fe;
  padding: 0 20px;
  transition: 0.2s;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: 'Poppins', sans-serif;
  line-height: 19px;
  font-size: 16px;
  font-weight: 400;
  transition: all 0.3s ease 0s;

  &:hover {
    background-color: $buttonHover;
  }

  &:active {
    background-color: $buttonActive;
  }

  &.bordered {
    position: relative;
    border-radius: 8px !important;

    &::before {
      content: "";
      position: absolute;
      border-radius: 8px;
      border: 2px solid $colorAccountBlue;
      background-color: #fff;
      left: 5px;
      top: 5px;
      z-index: -1;
      height: 100%;
      width: 100%;
      transition: all 0.3s ease-in-out 0s;
    }
  }

  &.bordered:hover {
    border-radius: 0;
    background-color: $colorBackgroundMainBlue;

    &::before {
      left: -2px;
      top: -2px;
      opacity: 0;
    }
  }

  &.withoutHoverColor {
    &:hover {
      background-color: $colorBackgroundMainBlue;
    }
  }

  img {
    position: absolute;
  }
}

.button.hover {
  &:hover {
    opacity: 0.8;
  }
}

.isLoading {
  .children {
    opacity: 0;
  }
}

.disabled {
  opacity: 0.3;
  cursor: default;
}

.accountBtn {
  border: none;
  background: #fff;
  box-shadow: 4px 4px 35px rgba(21, 17, 220, 0.05);
  border-radius: 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: $colorAccountBlue;
  display: flex;
  justify-content: center;
  position: relative;
  padding: 0 38px;
  transition: 0.2s;
  align-self: flex-end;

  .children {
    display: flex;
    align-items: center;
    justify-content: center;

    div {
      height: 56px;
      width: 56px;
    }
  }
}

.accountBtn.hover {
  &:hover {
    opacity: 0.5;
  }
}

@include for-mobile {
  .icon {
    display: flex;
    width: 48px;
    height: 48px;
    padding: 8px 10.69px 7.187px 11px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 38px;

    .children {
      min-width: 22px;
      min-height: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
