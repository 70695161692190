@import 'styles/variables';
@import 'styles/mixins';

$borderDarkColor: linear-gradient(286.17deg, #ffd37a 8.93%, #ffedd4 22.73%, #ffd37a 36.76%, #ffedd4 71.57%, #ffd37a 97.75%);

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: auto auto;
  max-width: 748px;
  width: 100%;
  min-height: 360px;
  background: #fff;
  box-shadow: 0 3px 40px rgba(0, 26, 67, 0.03);
  border-radius: 24px;
  padding: 36px 48px;
  font-family: 'Poppins', sans-serif;
  z-index: 100;

  .title {
    font-size: 24px;
    font-weight: 600;
    line-height: 31px;
    margin-bottom: 16px;
    color: $colorAccountBlack;
  }

  .subtitle {
    min-height: 44px;
    font-family: 'Poppins', sans-serif;
    line-height: 22px;
    font-size: 14px;
    color: $colorTextGray;
    font-weight: 300;
  }

  .comment {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
    margin-top: 54px;
    margin-bottom: 58px;
  }

  .nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-self: flex-end;
    gap: 48px;

    &:hover {
      cursor: pointer;
    }
  }

  .button {
    cursor: pointer;
    border-radius: 16px;
    width: 160px;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: $colorWhite;

    &Text {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: $colorAccountBlue;
    }
  }

  .btn {
    position: absolute;
    border: none;
    background: none;
    width: 14px;
    height: 14px;
    top: 36px;
    right: 20px;

    &:hover {
      opacity: 0.4;
    }
  }

  .main {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &.dark {
    background: linear-gradient($colorBgVip, $colorBgVip) padding-box, $borderDarkColor border-box;
    border: 1px solid transparent;
    border-radius: 8px;

    .title {
      color: #fff;
    }

    .subtitle {
      color: #fff;
      font-family: 'Inter', sans-serif;
    }
  }
}

@keyframes fadeIn {
  0% {
    transform: translateY(120%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes fadeDown {
  0% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(120%);
  }
}

@include for-mobile {
  .container {
    width: 100%;
    height: fit-content;
    max-height: 880px;
    padding: 24px 16px;
    margin: auto 0 0 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    animation: 300ms ease-in fadeIn;
  }

  .closedContainer {
    animation: 300ms ease-in fadeDown;
  }
}
