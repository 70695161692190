@import 'styles/variables';
@import 'styles/mixins';

.errWrapper {
  height: fit-content;

  &__content {
    display: flex;
    column-gap: 36px;
    align-items: center;
    margin-top: 24px;

    @include for-mobile {
      flex-direction: column;
      row-gap: 52px;
    }

    h3 {
      font-size: 24px;
      line-height: 1.29;
      font-weight: 600;
      margin-bottom: 12px;
    }

    img {
      width: 160px;
      height: 160px;
      object-fit: cover;
    }

    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 1.57;
      color: $colorAccountTextGray;
    }
  }

  .goToMainPageBtn {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    margin-right: 0;
    margin-left: auto;
    margin-top: 24px;

    @include for-mobile {
      width: 100%;
    }
  }
}

.account {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  font-family: 'Poppins', sans-serif;
}

.header {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400 !important;
}

.main {
  width: 100%;
  border-radius: 40px 40px 0 0;
  height: 100%;
  display: grid;
  grid-template-columns: 235px 1fr 272px;
  grid-template-areas: 'left content right';
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  @include for-less-desktop() {
    grid-template-columns: 1fr 272px;
    grid-template-rows: auto 56px;
    grid-template-areas:
      'content right'
      'left left';
  }

  @include for-mobile() {
    grid-template-columns: 1fr;
    grid-template-rows: auto 56px;
    grid-template-areas:
      'content'
      'left';
  }
}

.left {
  position: fixed;
  height: 100vh;
  grid-area: left;

  @include for-less-desktop() {
    height: 56px;
    width: 100%;
    bottom: 0;
  }
}

.right {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  grid-area: right;
}

.content {
  grid-area: content;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 44px);
  margin: 0 auto;
  margin-top: 44px;

  @include for-less-desktop() {
    height: calc(100vh - 100px);
  }

  &__title {
    font-family: 'Poppins', sans-serif;
    color: $colorAccountBlack;
    font-size: 36px;
    line-height: 31px;
    font-weight: 600;
  }
}

@include mobile {
  .left {
    width: 100%;
    bottom: 0;
    left: 0;
  }

  .main {
    position: relative;
    box-shadow: none;
    border-radius: 0;
  }

  .content {
    height: 100%;
    padding: 20px 16px;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    width: 100%;
    min-height: calc(100vh - 120px);
  }
}
