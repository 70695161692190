.react-calendar__viewContainer {
  display: flex;
}

.react-calendar__month-view {
  margin-top: 12px;
}

.react-calendar__month-view:nth-child(1) {
  margin-right: 30px;
}

.react-calendar--doubleView {
  padding: 30px;
}

.react-calendar__navigation {
  display: flex;
  justify-content: space-between;
}

.react-calendar__navigation__label {
  background: none;
  border: none;
  font-family: 'Inter', serif;
  font-weight: 600;
  font-size: 14px;
}

.react-calendar__navigation__label__divider {
  opacity: 0;
  margin: 0 20%;
}

.react-calendar__navigation__arrow {
  background: none;
  border: none;
  color: #1168f1;
  font-family: 'Inter', serif;
  font-weight: bold;
  font-size: 14px;
}

.react-calendar__month-view__weekdays__weekday abbr {
  font-family: 'Inter', serif;
  font-size: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #1d1d1d85;
  text-decoration: none;
}

.react-calendar__viewContainer button {
  border: none;
  background: none;
  margin-top: 10px;
  height: 30px;
  margin-right: 9px;
}

.react-calendar__month-view__days button {
  max-width: 30px !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #c3c3c3;
}

.react-calendar__tile--range,
.react-calendar__tile--rangeBothEnds {
  font-weight: 600;
  border-radius: 50%;
  background-color: #1168f1 !important;
  color: #fff;
}

@media screen and (max-width: 500px) {
  .react-calendar__viewContainer {
    flex-direction: column;
  }
}
