@import 'styles/variables';
@import 'styles/mixins';

$violet-color-line: #d099f9;
$yellow-color-line: #fbe695;
$green-color-line: #a6fcdb;
$gray-color-line: #aeaeae;

.coin {
  display: flex;
  align-items: center;
  column-gap: 12px;

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  &__content {
    h3 {
      font-size: 14px;
      font-weight: 400;
      line-height: 1.43;
      text-transform: uppercase;
      margin-bottom: 2px;
      color: $colorAccountBlack;
    }

    p {
      font-size: 12px;
      font-weight: 300;
      line-height: 1.5;
      color: $colorTextBlack;

      .green {
        color: $colorGreen;
        margin: 0 4px;
      }

      .red {
        color: red;
        margin: 0 4px;
      }

      .gray {
        color: $colorTextGray;
      }
    }
  }

  img {
    width: 38px;
    height: 38px;
  }
}
