.btn {
  border: none;
  background: transparent;
  position: relative;

  .copy {
    width: 100%;
    height: 100%;
    transition: all 0.3s ease 0s;

    &:hover {
      opacity: 0.7;
    }
  }

  .copySuccess {
    position: absolute;
    top: -1px;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scale(0);
    opacity: 0;
    transition: all 0.3s ease 0s;
    z-index: 3;

    &.active {
      opacity: 1;
      transform: scale(1.2);
    }
  }
}
