@import 'styles/variables';
@import 'styles/mixins';

.header {
  z-index: 4;
  position: relative;
  background-color: transparent;
  padding: 35px 0 35px;
  transition: background 0.3s;
  font-family: 'Poppins', sans-serif;
  font-weight: 400 !important;
  font-size: $smallFontSize;

  &.vipPage {
    background-color: $colorBgVip;
  }

  &_wallet {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 40px 0 40px;
  }
}

.wrapperPhoneBlock {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.phoneOrEmailInput {
  width: 100%;
}

.inputTitle {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 8px;
}

.headerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerLogo {
  display: flex;
  cursor: pointer;
  z-index: 8;

  &:hover {
    svg {
      opacity: 0.8;
    }
  }
}

.desktopOnly {
  display: block;
}

.headerMenuBtn {
  padding: 6px;
}

.headerMyWalletBtn {
  width: 108px;
  height: 40px;
  border-radius: 8px;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;

  @include for-mobile {
    display: none;
  }

  &_vip {
    background: linear-gradient(283.45deg, #ffd37a 13.43%, #ffedd4 25.75%, #ffd37a 38.27%, #ffedd4 69.35%, #ffd37a 92.71%);
    color: $colorBgVip;
  }

  &:active {
    opacity: 0.8;
  }
}

.headerLinks {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-left: 3%;

  .link_mobile {
    display: none;

    @include for-less-tablet {
      display: block;
    }
  }

  p {
    color: $colorTextBlackMain;
    letter-spacing: 0.4px;

    &:hover {
      color: $colorAccountBlue;
      opacity: 0.8;
    }
  }

  &_wallet {
    a,
    span,
    p {
      color: #1168f1;
      letter-spacing: 0.4px;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &_vip {
    a,
    span,
    p {
      color: #fff !important;
    }
  }

  .navLink_main {
    color: $colorWhite;
  }

  .navLink_group {
    position: relative;
    margin-right: 22px;
    cursor: pointer;
    color: $colorTextBlackMain;

    &:hover {
      color: $colorAccountBlue;

      &::before {
        border-top-color: $colorAccountBlue;
      }
    }

    @include for-less-tablet() {
      display: none;
    }

    .link__groups {
      position: absolute;
      flex-direction: column;
      padding: 16px 16px 12px 16px;
      background-color: $colorWhite;
      border-radius: 16px;
      box-shadow: 4px 4px 24px 0 rgba(0, 0, 0, 0.1);
      row-gap: 2px;
      min-width: 140px;
      left: -20px;
      top: 27px;
      overflow: hidden;
      display: none;

      a {
        text-align: left;
        margin-left: 0;
        line-height: 1.57;
        color: $colorTextBlackMain;
      }

      a:hover {
        color: $colorAccountBlue;
      }
    }

    &::before {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid $colorTextBlackMain;
      right: -20px;
      top: 50%;
      transform: translateY(-50%);
      transition: top 0.5s ease 0s, transform 0.5s ease 0s;
    }

    &.opened {
      &::before {
        transform: rotate(180deg);
        top: 30%;
      }

      .link__groups {
        display: flex;
      }
    }

    &_wallet {
      color: $colorWhite;

      .link__groups {
        background-color: $colorBackgroundMainBlue;

        a {
          color: $colorWhite;
        }
      }

      &::before {
        border-top: 6px solid $colorWhite;
      }
    }

    &_vip {
      color: #fff;

      .link__groups {
        a {
          color: $colorAccountBlack !important;
        }
      }

      &:hover {
        color: #fff;

        &::before {
          border-top: 6px solid $colorWhite;
        }
      }

      &::before {
        border-top: 6px solid $colorWhite;
      }
    }
  }

  .signInWallet,
  .signInVip {
    color: $colorWhite;
  }
}

.navLinks {
  display: flex;
}

.navLink {
  & + & {
    margin-left: 26px;
    color: $colorTextBlackMain;
  }

  &:hover {
    color: $colorAccountBlue;
  }

  &_wallet + & {
    color: $colorWhite !important;
  }
}

.menuToggleBtn {
  position: relative;
  float: right;
  z-index: 8;
}

.signInButton {
  width: 100px;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;

  &:active {
    opacity: 0.8;
  }
}

.signUpButton {
  line-height: 24px;
  text-align: left;
  width: 108px;
  height: 40px;
  border-radius: 8px;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;

  &:active {
    opacity: 0.8;
  }

  &_vip {
    background: linear-gradient(283.45deg, #ffd37a 13.43%, #ffedd4 25.75%, #ffd37a 38.27%, #ffedd4 69.35%, #ffd37a 92.71%);
    color: $colorBgVip;
  }
}

.headerLinksBox {
  border: none;
  background: none;
  display: flex;
  align-items: center;
  white-space: nowrap;

  span {
    margin-left: 10px;
  }

  .inverted {
    color: white;
  }
}

.dangerLi {
  margin-top: 5px;
  color: $colorLightRed;
}

.mobileLogout {
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  background-color: transparent;
  border: none;
  outline: none;

  span {
    color: $colorWhite;
    font-family: inherit;
  }
}

.accountBlock {
  width: calc(100% - 48px);
  max-width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 24px 24px 0 24px;

  @include for-small-height {
    margin: 0 24px 0 24px;
  }

  &_wallet {
    button {
      span {
        color: $colorWhite !important;
      }
    }
  }

  button,
  a {
    display: flex;
    align-items: center;
    background-color: transparent;

    span {
      margin-left: 8px;
      color: $colorWhite;
    }
  }
}

.additionalAccount {
  width: calc(100% - 48px);
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
  margin-top: 36px;

  @include for-small-height {
    margin-top: 20px;
  }
}

@include for-mobile {
  .headerLinks {
    margin-left: 0;
    color: $colorTextWhite;
  }
}

@include for-mobile-tablet-and-xs-tablet {
  .headerLinks {
    position: fixed;
    width: 100%;
    height: 100%;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 7;
    background: $colorBackgroundMainBlue;

    &_vip {
      background: $colorBgVip;
    }

    @include for-small-height {
      margin-top: 20px;
    }
  }

  .header {
    padding: 10px 0;
    background: $colorBackgroundMainBlue;

    &.vipPage {
      background: $colorBgVip;
    }
  }

  .headerOpened {
    z-index: 9999;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;

    a,
    p {
      color: $colorTextWhite;
    }

    .headerLinks {
      display: flex;
    }

    .navLink {
      color: $colorTextWhite;
      margin-left: 0;
      margin-top: 35px;

      @include for-small-height {
        margin-top: 20px;
      }
    }

    .mobileSignIn {
      margin-top: 84px;
    }
  }

  .menuToggleBtn {
    width: 30px;

    &::after,
    &::before,
    & div {
      background-color: $colorTextWhite;
      border-radius: 3px;
      content: '';
      display: block;
      height: 2px;
      margin: 9px 0;
      transition: all 0.2s ease-in-out;
    }
  }

  .menuToggleBtnOpened {
    &::after,
    &::before,
    & div {
      background-color: $colorWhite;
    }

    &::before {
      transform: translateY(11px) rotate(135deg);
    }

    &::after {
      transform: translateY(-11px) rotate(-135deg);
    }

    & div {
      transform: scale(0);
    }
  }

  .menuToggleBtnInverted {
    &::after,
    &::before,
    & div {
      background-color: $colorWhite;
    }
  }

  .signInButton {
    order: 2;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    border: 1px solid $colorWhite;
    border-radius: 8px;
    width: calc(100% - 48px);
    height: 40px;
    max-width: 350px;
    margin: 16px 24px 0 !important;

    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 0.6;
    }
  }

  .signUpButton {
    background-color: $colorWhite;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    border: 1px solid $colorWhite;
    border-radius: 8px;
    width: calc(100% - 48px);
    max-width: 350px;
    margin: 35px 24px 0 !important;
    color: $colorAccountBlue;

    @include for-small-height {
      margin: 20px 24px 0 !important;
    }

    &_vip {
      color: $colorBgVip;
    }

    &:hover {
      opacity: 0.8;
      background-color: $colorWhite;
    }

    &:active {
      opacity: 0.6;
    }
  }

  .desktopOnly {
    display: none;
  }
}

@include for-desktop {
  .mobileOnly {
    display: none;
  }
}

@include for-less-desktop {
  .mobileOnly {
    display: none;
  }
}

@include for-less-tablet {
  .mobileOnly {
    display: block;
  }

  .navLinks {
    flex-direction: column;
    text-align: center;
    align-items: center;
    width: 100%;
  }
}
