@import 'variables';

.PhoneInput {
  width: 100%;

  --PhoneInputCountryFlag-borderWidth: 0;
  --PhoneInputCountryFlag-height: 18px;
  --PhoneInputCountrySelectArrow-color: #1168f1;
  --PhoneInputCountrySelectArrow-opacity: 1;
  --PhoneInputCountrySelectArrow-width: 6px;
}

.PhoneInputCountryIcon {
  margin-right: 12px;
  border-radius: 4px;
  overflow: hidden;
}

.PhoneInputCountry {
  border-radius: 16px 0 0 16px;
  padding: 16px;
  box-shadow: 4px 4px 35px 0 rgba(21, 17, 220, 0.05);
  margin-right: 0 !important;
}

.PhoneInputInput {
  padding: 16px;
  border-radius: 0 16px 16px 0;
  border: none;
  background-color: #f8f9fd;
  color: $colorTextBlackMain;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;

  &::placeholder {
    color: $colorTextBlackMain;
    opacity: 0.2;
  }
}
