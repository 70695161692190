@keyframes Animation {
  from {
    left: -80%;
  }

  to {
    left: 180%;
  }
}

.skeleton {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background: #fafafa;
  overflow: hidden;
  position: relative;

  > div {
    position: absolute;
    top: 0;
    animation: Animation 2s ease-in-out infinite;
    width: 80%;
    height: 100%;
    background: linear-gradient(270deg, #f8f9fd00 0%, #eeeff7 46.35%, #f8f9fd00 100%);
  }
}
